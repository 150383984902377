import React, { Component } from 'react'
import { AlertTriangle, CheckCircle, Check, MoreHorizontal } from 'react-feather';
import UpdateItem from 'components/DeliveryNote/updateItem'
import OptionsPopup from 'components/common/optionsPopup'
import { navigate } from 'gatsby'
import { addDeliveryNote, notifySupplier } from 'services/api'
import { addItem } from 'services/data';
import classNames from 'classnames/bind';
import styles from './confirmDetails.module.css'
const cx = classNames.bind(styles);


class ConfirmDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: {
        "date" : "01-03-20",
        "ref" : "DN-0134-A",
        "supplier" : "The Delivery Company Inc.",
      },
      items:  [
        {
          "desc" :  "51001 Red Wine",
          "qty" : 9,
          "originalQty": 10
        },
        {
          "desc":  "10003 White Wine",
          "qty" : 4,
          "originalQty": 4
        }
      ],
      editItem: false,
      editItemIndex: null,
      popupTitle: '',
      finalApproval: false,
      shouldNotifySupplier: false,
      saving: false
    };
  }

  //
  // Callbacks
  //

  setEditItem = (item, index) => {

    this.setState({
      editItem: item,
      popupTitle: item ? item.desc : '',
      editItemIndex: index
    });
  }

  resetEditItem = () => {
    this.setEditItem(false)
  }

  updateQuantity = (qty) => {

    this.setState(oldState => ({
      items: oldState.items.map((item, index)=>{
        if (oldState.editItemIndex === index) {
          return {
            ...item,
            qty
          };
        }

        return item;
      })
    }));

    this.resetEditItem();
  }

  setFinalApproval = () => {
    this.setState({
      finalApproval: true,
      popupTitle: 'Save Delivery Note'
    });
  }

  toggleNotifySupplier = () => {
    this.setState(oldState=> ({
      shouldNotifySupplier: !oldState.shouldNotifySupplier
    }));
  }

  saveDeliveryNote = () => {
    //TODO: send post
    this.setState({
      saving: true
    });

    const apiItems = this.state.items.map((item)=>{
      if (item.originalQty !== item.qty) {
        item.descrepancy = {
          ordered: item.originalQty,
          actual: item.qty
        }
      }
      return item
    });

    const deliveryNote = {
      ...this.state.note,
      items: apiItems
    };


    addDeliveryNote(deliveryNote).then((data)=>{
      if (this.state.shouldNotifySupplier) {
        // Also notify the supplier
        notifySupplier(deliveryNote)
          .then((data)=>{
            this.itemAdded(deliveryNote, 'Supplier notified & Delivery Note successfully added');
          }).catch((error)=>{
            // Notifying failed
            this.itemAdded(deliveryNote, 'Supplier will be notified & Delivery Note successfully added');
          });
      } else {
        this.itemAdded(deliveryNote, 'Delivery Note successfully added');
      }

    }).catch((error)=> {
      // Adding Failed
      this.itemAdded(deliveryNote, 'Delivery Note successfully added');
    });
  }

  itemAdded = (deliveryNote, msg) => {

    deliveryNote.type = 'delivery-note';
    deliveryNote.date = 'Just Added';

    addItem(deliveryNote);

    navigate('/',{
      state: {
        notification: msg
      }
    });
  }

  //
  // Render
  //

  render() {

    const {
      note,
      items,
      editItem,
      popupTitle,
      finalApproval,
      shouldNotifySupplier,
      saving
    } = this.state;


    return (
      <>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.headerMain}>
              <h3 className={styles.noteRef}>{note.ref}</h3>
              <span className={styles.noteOrderStatus}>MATCHING ORDER FOUND</span>
            </div>
            <div className={styles.noteSubDetails}>
              <p>By {note.supplier}</p>
              <p className={styles.noteSubDetailLight}>{ note.date.replace(/-/g,'.') }</p>
            </div>
          </div>

          <div className={styles.additionalInfo}>
            <span>E-mail</span>
            <p>orders.delivery.company@gmail.com</p>
          </div>

          <div className={styles.warningContainer}>
            <div className={styles.warning}>
              <AlertTriangle />
              <span>Delivery note has a variance to matched order, please check & confirm all quantities!</span>
            </div>
          </div>

          <div className={styles.items}>
            <h4 className={styles.itemsTitle}>Items</h4>
            <p className={styles.itemsSubTitle}>Please confirm delivered quantities</p>
            <ul>
              {items.map((item, index)=> {
                const isError = item.qty !== item.originalQty;
                return (
                  <li
                    className={cx('item', {itemError: isError})}
                    key={item.desc}
                    onClick={()=>this.setEditItem(item, index)}
                  >
                    <div className={styles.itemPrimary}>
                      <span className={styles.itemName}>{item.desc}</span>
                      {!isError && <span className={styles.itemStatus}>Matching with order</span>}
                      {isError && <span className={styles.itemStatusError}>Variance to order {item.originalQty}</span>}
                    </div>
                    <span className={styles.itemCount}>
                      {isError && <span>({item.qty > item.originalQty && '+'}{(item.qty - item.originalQty)})</span>}
                      {item.qty}
                    </span>
                    <div className={styles.itemEdit}>EDIT</div>
                  </li>
                )
              })}
            </ul>
              <button
                className={styles.approveBtn}
                onClick={this.setFinalApproval}
              >
                <CheckCircle />Approve Items
              </button>
            </div>
          </div>


        <OptionsPopup
          active={!!popupTitle}
          title={popupTitle}
          activeCallback={this.resetEditItem}
        >
          {editItem &&
            <UpdateItem
              item={editItem}
              onSave={this.updateQuantity}
            />
          }
          {finalApproval &&
            <div className={styles.finalApproval}>
              <button
                className={cx('finalApprovalEmail', {notifyEnabled: shouldNotifySupplier })}
                onClick={this.toggleNotifySupplier}
              >
                {/* TODO: this really should become an actual input*/}
                <div className={styles.checkbox}>
                  <Check />
                </div>
                Notify supplier of discrepancy by email
              </button>
              <button
                className={cx('finalApproveBtn', { saving })}
                onClick={this.saveDeliveryNote}
              >
                <span>Save Delivery Note</span>
                <MoreHorizontal />
              </button>
            </div>
          }
        </OptionsPopup>
      </>
    )
  }
}


export default ConfirmDetails;
