// import { Link } from "gatsby"
import React from "react"
import PropTypes from 'prop-types'
import styles from './optionsPopup.module.css'
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const OptionsPopup = ({children, active, activeCallback, title}) => {

  return (
    <div className={cx('container', {display:active})}>
      <button className={styles.bg} onClick={activeCallback} />

      <div className={styles.content}>
        {title &&
          <div className={styles.header}>
            {title}
          </div>
        }
        {children}
      </div>
    </div>
  );
};

OptionsPopup.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string,
  activeCallback: PropTypes.func
}

export default OptionsPopup
