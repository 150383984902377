import React from 'react'
import styles from './notificationBar.module.css'
import {CheckCircle, X} from 'react-feather';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
const cx = classNames.bind(styles);


class NotificationBar extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			isVisible: false
		};
	}

	//
	// Lifecycle
	//

	componentDidMount() {
		if (this.props.text) {
			// Add in delay to make it async and make sure it animates
			setTimeout(this.showNotification, 0);
		}
	}

	componentDidUpdate(prevProps){
    if (this.props.text && this.props.text.length > 0 && this.props.text !== prevProps.text) {
			this.showNotification();
    }
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	//
	// Handlers
	//

	showNotification = () => {
		this.setState({ isVisible: true });
		this.timer = setTimeout(this.closeNotification, 3000);
	}

	closeNotification = () => {
		this.setState({ isVisible: false });
	}

	//
	// Render
	//

	render(){
		return(
			<div className={cx('container', { isVisible: this.state.isVisible })}>
				<div className={styles.content}>
					<CheckCircle className={styles.check}/>
					<div className={styles.text}>{this.props.text}</div>
				</div>
				<button className={styles.closeButton} onClick={this.closeNotification}>
					<X className={styles.cross}/>
				</button>
			</div>
		)
	}
}

NotificationBar.propTypes = {
	text: PropTypes.string
}

export default NotificationBar;
