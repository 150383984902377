import PropTypes from 'prop-types';
import React from 'react'
import { Link } from '@reach/router'
import styles from './quickAction.module.css'
import * as FeatherIcon from 'react-feather'

//TODO: if header will be used in more flows, it should be moved into it's own component(s)

const QuickAction = ({name, priority, icon, to}) => {

  const itemWidth = (priority === 1) ? 50 : 33;
  const ActionIcon = FeatherIcon[icon];

  return (
    <Link
      className={styles.quickAction}
      to={to}
      style={{ width: `calc(${itemWidth}% - 8px)` }}
    >
      <ActionIcon />
      <span className={styles.description}>{name}</span>
    </Link>
  );
}

QuickAction.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  to: PropTypes.string,
  priority: PropTypes.number
}

QuickAction.defaultProps = {
  priority: 2,
  to: '/not-found',
  icon: 'Camera'
}

export default QuickAction;
