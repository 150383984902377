import { isBrowser } from 'services/helpers'

const items = [
	{
		"uuid": "76a138b7-852a-4dc3-aa4e-9117652449b3",
		"supplier": "The Vino Company",
		"ref": "DN-0132-B",
		"date": "2019-01-02",
		"items": [
			{
				"desc": "fefe",
				"qty": 2
			}
		],
		"type": "delivery-note"
	},
	{
		"uuid": "76a138b7-852a-4dc3-aa4e-9117652449b3",
		"supplier": "Pepsi",
		"ref": "INV-0127-A",
		"date": "2019-01-02",
		"items": [
			{
				"desc": "Pepsi Can",
				"qty": 2
			}
		],
		"type": "invoice"
	},
	{
		"uuid": "76a138b7-852a-4dc3-aa4e-9117652449b3",
		"supplier": "Growyze",
		"ref": "WA-02145",
		"date": "2019-01-02",
		"items": [
			{
				"desc": "Red Wine",
				"qty": 2
			}
		],
		"type": "waste"
	}
];

const dataKey = "growyzeData";


export const getData = () =>
  isBrowser() && window.localStorage.getItem(dataKey)
    ? JSON.parse(window.localStorage.getItem(dataKey))
    : { items }

export const addItem = item => {
  if (isBrowser()) {
    let data = getData();
    // Add item to beginning of array
    data.items.unshift(item);
    // Update localStorage
    window.localStorage.setItem(dataKey, JSON.stringify(data))
  }
}

export const removeData = () => {
  if (isBrowser()) {
    window.localStorage.removeItem(dataKey);
  }
}
