import React, { useState } from "react"
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import styles from './header.module.css';
import { Sliders, Search, ArrowLeft, X, LogOut, Package } from 'react-feather'
import { logout } from 'services/auth'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)


const Header = ({ siteTitle, back }) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);


  return (
    <>
      <header className={styles.container}>
          { back
            ? <Link to="/" state={{backwards: true}}><ArrowLeft /></Link>
            : <Sliders onClick={()=>setIsMenuOpen(true)} />
          }
          <img className={styles.logo} src="/images/growyze-logo.svg" alt="logo" />
          <Link to="/not-found"><Search /></Link>
      </header>
      <div className={cx('menu', {isOpen: isMenuOpen})}>
        <div className={styles.menuBg} onClick={()=>setIsMenuOpen(false)} />
        <div className={styles.menuContent}>
          <X className={styles.menuClose} onClick={()=>setIsMenuOpen(false)}/>
          <div className={styles.menuItems}>
            <div className={styles.menuItemsTop}>
              <Link to="/list/delivery-notes" className={styles.menuItem}><Package />View Delivery Notes</Link>
            </div>
            <button className={styles.menuItem} onClick={logout}><LogOut />Logout</button>
          </div>
        </div>
      </div>
    </>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  back: PropTypes.bool
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
