import React, { Component } from 'react'
import styles from './ListDeliveryNote.module.css'
import ActivityListItem from 'components/Home/activityListItem.js'
import { Loader } from 'react-feather'
import { getDeliveryNotes } from 'services/api'
import Header from 'components/common/header'

class ListDeliveryNote extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notes: null,
      loading: true
    }
  }

  componentDidMount() {
    // do Api call
    getDeliveryNotes()
      .then(data=> {
        this.setState({
          notes: data.data.reverse(),
          loading: false
        });
      })
  }

  render() {
    return (
      <div>
        <Header back />
        <div className={styles.content}>
          <h1 className={styles.title}>Latest Deliveries</h1>
          {this.state.loading &&
            <div className={styles.loading}>
              <Loader />Loading...
            </div>
          }
          {!this.state.loading && this.state.notes.length > 0 &&
            <div className={styles.itemContainer}>
              {this.state.notes.map((item, id)=> (
                <ActivityListItem
                  type="delivery-note"
                  key={`${item.ref}-${id}`}
                  title={item.ref}
                  description={item.supplier}
                  date={item.date}
                />
                ))}
            </div>
          }
        </div>
      </div>
    )
  }

}

export default ListDeliveryNote;
