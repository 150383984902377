import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "services/auth"
import { isBrowser } from 'services/helpers'


const PrivateRoute = ({ component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/login` && isBrowser()) {
    navigate("/login")
    return null
  }

  const Component = component;

  return <Component {...rest} />
}


export default PrivateRoute
