import React from 'react'
import styles from './quickActions.module.css'
import QuickAction from 'components/Home/quickAction';
import { getUser } from 'services/auth'



//TODO: if header will be used in more flows, it should be moved into it's own component(s)

const QuickActions = () => (
  <div className={styles.container}>
    <h2 className={styles.intro}>Hi {getUser().username},</h2>
    <h3 className={styles.introTitle}>Your Quick Actions</h3>
    <div className={styles.content}>
      <QuickAction icon="Package" name="Delivery Note" to="delivery-note" />
      <QuickAction icon="FileText" name="Invoice" />
      <QuickAction icon="Clipboard" name="Order" />
      <QuickAction icon="RefreshCw" name="Stocktake" priority={1} />
      <QuickAction icon="Slash" name="Waste" priority={1} />
    </div>
  </div>
)

export default QuickActions;
