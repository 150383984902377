import React from 'react'
import styles from './activityListItem.module.css'
import {Package, FileText, Slash, Clipboard, RefreshCw} from 'react-feather';
import classNames from 'classnames/bind';
import { Link } from 'gatsby';
const cx = classNames.bind(styles);

const ActivityListItem = ({type, title, description, status, date}) => {

	let ActionIcon
	let iconBg
	let warning

	switch (type) {
		case "delivery-note":
			ActionIcon = Package;
			iconBg = 'deliveryNoteIcon';
			break;
		case "invoice":
			ActionIcon = FileText;
			iconBg = 'invoiceIcon';
			break;
		case "waste":
			ActionIcon = Slash;
			iconBg = 'wasteIcon';
			break;
		case "order":
			ActionIcon = Clipboard;
			iconBg = 'wasteIcon';
			break;
		case "stocktake":
			ActionIcon = RefreshCw;
			iconBg = 'wasteIcon';
			break
		default:
			ActionIcon = FileText;
			iconBg = 'invoiceIcon'
	}

	if (status === "Problem Detected") {
		warning = 'statusWarn';
	}

	return(
		<Link to='/not-found' className={styles.link}>
			<div  className={styles.itemRow}>

				<div className={styles.itemRowLeft}>
					<div className={cx('iconBg', iconBg)}>
						<ActionIcon className={styles.icon}/>
					</div>
					<div>
						<p className={styles.title}>{title}</p>
						<p className={styles.description}>{description}</p>
					</div>

				</div>

				<div className={styles.itemRowRight}>
					<div>
						<p className={cx('status', warning)}>Delivered</p>
						<p className={styles.description}>{date}</p>
					</div>
				</div>
			</div>
		</Link>
	)
}

export default ActivityListItem;
