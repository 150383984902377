import PropTypes from 'prop-types';
import React from 'react'
import { ArrowLeft } from 'react-feather'
import { Link } from '@reach/router'
import styles from './navigationBar.module.css'
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);


const NavigationBar = ({ title, subtitle, back, transparent }) => (
	<nav className={cx('header', {transparent})}>

		<div className={styles.backButton}>
			<Link to={back} state={{backwards: true}}><ArrowLeft className={styles.backIcon}/></Link>
		</div>

		<div className={styles.headerMain}>
			<h2 className={styles.title}>{title}</h2>
			<p className={styles.subtitle}>{subtitle}</p>
		</div>

		<div className={styles.placeHolder}>

		</div>

	</nav>
	)

	NavigationBar.propTypes = {
		title: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
		back: PropTypes.string,
		transparent: PropTypes.bool
	}

	NavigationBar.defaultProps = {
		back: '/'
	}


  export default NavigationBar;
