import React, { Component } from 'react'
import PropTypes from 'prop-types';
import styles from './updateItem.module.css'

class UpdateItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      quantity: props.item.qty
    };
  }

  //
  // Handlers
  //

  decreaseQuantity = () => {
    this.setState((oldState) => ({
      quantity: oldState.quantity - 1
    }));
  }

  increaseQuantity = () => {
    this.setState((oldState) => ({
      quantity: oldState.quantity + 1
    }));
  }

  confirmQuantity = () => {
    this.props.onSave(this.state.quantity);
  }

  //
  // Renders
  //

  render () {
    return (
      <div className={styles.container}>
        <h5 className={styles.title}>Update Quantity</h5>
        <div className={styles.updateQuantity}>
          <button onClick={this.decreaseQuantity}>-</button>
          {this.state.quantity}
          <button onClick={this.increaseQuantity}>+</button>
        </div>
        <button onClick={this.confirmQuantity} className={styles.btn}>Confirm Quantity</button>
      </div>
    )
  }
}

UpdateItem.propTypes = {
  item: PropTypes.object,
  onSave: PropTypes.func.isRequired
};

export default UpdateItem;
