import React from 'react'
import styles from './Login.module.css'
import classNames from 'classnames/bind';
import { navigate } from "gatsby";
import { handleLogin, isLoggedIn } from "services/auth"
const cx = classNames.bind(styles);

class Login extends React.Component {
	state = {
		username: '',
		password: '',
		error: ''
	}

	handleUpdate = (ev) => {
		this.setState({
			[ev.target.name]: ev.target.value
		});
	}

	handleSubmit = (ev) => {
		ev.preventDefault()
		// login user
		const successLogin = handleLogin(this.state);

		if (successLogin) {
			navigate(`/`);
		} else {
			// TODO: error handling
			this.setState({
				error: 'Please check your username and password and try again.'
			});
		}
	}

	render() {

		if (isLoggedIn()) {
			navigate(`/`);
		}

		return (
			<div className={styles.container}>

				<div className={styles.logo} />

				<form
					className={styles.content}
					onSubmit={this.handleSubmit}
				>
					<div className={styles.welcomeContent}>
						<h1 className={styles.bold}>Welcome,</h1>
						<h2>Sign in to continue</h2>
					</div>
					<input
						name="username"
						type='text'
						placeholder='Username'
						className={cx('contentBlock', 'inputs')}
						onChange={this.handleUpdate}
					/>
					<input
						name="password"
						type='password'
						placeholder='Password'
						className={cx('contentBlock', 'inputs')}
						onChange={this.handleUpdate}
					/>
					<button className={cx('contentBlock', 'signIn')}>Sign In</button>
					{this.state.error.length > 0 &&
						<div className={styles.error}>
						 	{this.state.error}
						</div>
					}
				</form>

				<div>
					<p className={styles.noAccount}>
							Don't have an account? <a href='mailto:kati@growyze.com' className={styles.bold}>Get in touch</a>
					</p>
				</div>

			</div>
		);
	}
}

export default Login;
