import React from 'react'
import { Location } from '@reach/router'
import Header from 'components/common/header'
import ActionBar from 'components/common/actionBar'
import QuickActions from 'components/Home/quickActions'
import ActivityList from 'components/Home/activityList'
import styles from './Home.module.css'
import NotificationBar from 'components/common/notificationBar'

const Home = (props) => (
  <Location>
    {({ location }) => (
        <div className={styles.container}>
          <Header />
          <div className={styles.content}>
      			<NotificationBar text={location.state && location.state.notification} />
            <QuickActions />
            <ActivityList />
          </div>
          <ActionBar />
        </div>
      )}
  </Location>
)

export default Home;
