import React from "react"
import { Router, Location } from '@reach/router'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Layout from "components/layout"
import PrivateRoute from 'components/privateRoute'
import Home from 'components/Home/Home'
import DeliveryNote from 'components/DeliveryNote/DeliveryNote'
import ListDeliveryNote from 'components/ListDeliveryNote/ListDeliveryNote'
import NotFound from 'components/NotFound/NotFound'
import Login from 'components/Login/Login'
import 'styling/navigation.css';
import classNames from 'classnames'


const IndexPage = () => (
  <Layout>
    <TransitionRouter>
        <PrivateRoute path="/" component={Home} />
        <PrivateRoute path="/delivery-note" component={DeliveryNote} />
        <PrivateRoute path="/list/delivery-notes" component={ListDeliveryNote} />
        <Login path="/login" component={Login} />
        <NotFound default component={NotFound} />
    </TransitionRouter>
  </Layout>
)

let prevLocationPath = '';

const TransitionRouter = ({ children }) => (
  <Location>
    {({ location }) => {

      const isInitialScreen = !prevLocationPath.length;

      prevLocationPath = location.pathname;

      return (
        <TransitionGroup className={classNames("transitionContainer", {NavigationIsBackwards: location.state && !!location.state.backwards})}>
          <CSSTransition
            key={location.key}
            classNames={'page' }
            className={classNames("page", {
              pageInitial: isInitialScreen
            })}
            timeout={500}
          >
            <Router location={location}>
              {children}
            </Router>
          </CSSTransition>
        </TransitionGroup>
      )
    }}
  </Location>
);



export default IndexPage
