import { navigate } from 'gatsby'
import { isBrowser } from 'services/helpers'
import { removeData } from 'services/data';


export const getUser = () =>
  isBrowser() && window.localStorage.getItem("growyzeUser")
    ? JSON.parse(window.localStorage.getItem("growyzeUser"))
    : {}

const setUser = user =>
  window.localStorage.setItem("growyzeUser", JSON.stringify(user))

export const handleLogin = ({ username, password='' }) => {
  if (password.toLowerCase() === `hello`) {
    setUser({
      username: username,
      email: `test@growyze.com`,
    });
    return true;
  }
  return false
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.username
}


export const logout = callback => {
  setUser({})
  removeData();
  if (typeof callback == 'function') {
    callback()
  } else {
    navigate('/')
  }
}
