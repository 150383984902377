import React from 'react'
import Header from 'components/common/header'
import { Lock } from 'react-feather'
import { Link } from '@reach/router'
import styles from './NotFound.module.css'
// import Layout from 'components/common/Layout'

const NotFound = () => (
  <div className={styles.container}>
    <Header back />
    <div className={styles.content}>
      <Lock className={styles.icon}/>
      <h1 className={styles.title}>No access to this feature</h1>
      <p className={styles.copy}>You don’t seem to have access to this feature yet, please <a href='mailto:kati@growyze.com'>Get in touch</a> for more info</p>
      <Link to="/" className={styles.btn}>Back to home</Link>
    </div>
  </div>
)

export default NotFound;
