import React from 'react'
import styles from './activityList.module.css'
import { getData } from 'services/data'
import ActivityListItem from 'components/Home/activityListItem.js'


const ActivityList = ({activity: activityBackup}) => (
  <div className={styles.container}>
    <h2 className={styles.intro}>Recent Activity</h2>
    <div>
			{getData().items.map((item, id) => (
					<ActivityListItem
						type={item.type}
						key={`${item.ref}-${id}`}
						title={item.ref}
						description={item.supplier}
						date={item.date}
					/>
			))}
    </div>
  </div>
)

export default ActivityList;
