import React, { useState } from "react"
import { Link } from "gatsby"
import { Home, Plus, Bell } from 'react-feather'
import OptionsPopup from 'components/common/optionsPopup'
import * as FeatherIcon from 'react-feather'
import styles from './actionBar.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)



// TODO: move this to constants file so it can be re-used
const quickActions = [
  {name: 'Delivery Note', icon: 'Package', link: '/delivery-note'},
  {name: 'Invoice', icon: 'FileText'},
  {name: 'Order', icon: 'Clipboard'},
  {name: 'Stocktake', icon: 'RefreshCw'},
  {name: 'Waste', icon: 'Slash'}
];


const QuickActionBarItem = ({ action }) => {
  const ActionIcon = FeatherIcon[action.icon];

  return (
    <Link
      className={styles.quickAction}
      to={action.link || '/not-found'}
    >
      <ActionIcon />
      <span>{action.name}</span>
    </Link>
  )
};


const ActionBar = () => {

  const [displayOptions, setDisplayOptions] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <button className={cx('icon',{active: true})}><Home /></button>
        <button className={cx('icon',{active: false})} onClick={()=>setDisplayOptions(true)}><Plus /></button>
        <button className={cx('icon',{active: false})}><Bell /></button>

      </div>
      <OptionsPopup
        active={displayOptions}
        activeCallback={()=>setDisplayOptions(false)}
        title="Record new action"
      >
        <div className={styles.quickActions}>
          {quickActions.map((act) => <QuickActionBarItem key={act.name} action={act} />)}
        </div>
      </OptionsPopup>
    </>
  );
}

export default ActionBar
