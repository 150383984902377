const apiURL = 'https://growyze-api.byundefined.com/';

export const getDeliveryNotes = () => {

  return fetch(apiURL + 'growyze/delivery-notes', {
      mode: 'cors', // no-cors, *cors, same-origin
    }).then((res)=>res.json())
}

export const addDeliveryNote = (deliveryNote) => {
  return fetch(apiURL + 'growyze/delivery-notes', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
   },
    body: JSON.stringify(deliveryNote)
  }).then((res)=>res.json());
}


export const notifySupplier = (deliveryNote) => {
  return fetch(apiURL + 'notify-supplier', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
   },
    body: JSON.stringify(deliveryNote)
  }).then((res)=>res.json());
}
