import React, { useRef, useState, useCallback } from 'react'
import Webcam from "react-webcam";
import { AlertTriangle } from 'react-feather';
import NavigationBar from 'components/common/navigationBar'
import ConfirmDetails from 'components/DeliveryNote/confirmDetails'
import classNames from 'classnames/bind';
import styles from './DeliveryNote.module.css'
import { Link } from '@reach/router';

const cx = classNames.bind(styles);


const DeliveryNote = () => {

  const webcamRef = useRef(null);
  const fileRef = useRef(null);
  const [dataUri, setDataUri] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [notSupported, setNotSupported] = useState(false);


  const setStep2 = (dataUri) => {
    setDataUri(dataUri);
    setCurrentStep(2);
    setTimeout(()=> {
      setCurrentStep(3);
    }, 3000);
  }


  const capture = useCallback(
    () => {
      const dataUriSrc = webcamRef.current.getScreenshot();
      setStep2(dataUriSrc);
    },
    [webcamRef]
  );

  const captureFile = useCallback(
    (ev) => {
      let file = ev.target.files[0];
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        // convert image file to base64 string
        setStep2(reader.result);
      }, false);


      if (file) {
        reader.readAsDataURL(file);
      }
    }
  )

  const videoConstraints = {
     facingMode: "environment"
   };

  let content = (
    <>
      <Webcam
        audio={false}
        height={window.innerHeight}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={window.innerWidth}
        className={styles.camera}
        onUserMediaError={()=>setNotSupported(true)}
        videoConstraints={videoConstraints}
      />
      <input
        type="file"
        ref={fileRef}
        accept="image/*"
        style={{visibility: 'hidden'}}
        onChange={captureFile}
      />
      {notSupported && <div className={styles.notSupported}>
        <AlertTriangle />
        Camera access is not supported on this device, please upload a file
        <button
          className={styles.notSupportedBtn}
          onClick={()=>{
            fileRef.current.click();
          }}
        >Upload</button>
      </div>}
      {!notSupported &&
        <div className={styles.introContainer}>
          <div className={styles.intro}>
            <div className={styles.introIcon} /> Point the camera at your document
          </div>
        </div>
      }

      <div className={styles.scanActions}>
        <button className={cx('scanAction', 'active')}>Scan</button>
        <button onClick={capture} className={styles.scanBtn} />
        <button
          onClick={()=>{
            fileRef.current.click();
          }}
        className={styles.scanAction}>Upload</button>
      </div>
      {/* <button

        className={cx('btn','btnCapture')}
      >
        <Camera />Take Picture
      </button> */}
    </>
  );

  if (dataUri) {
    // Confirm screen
    content = (
      <>
        <img alt="Delivery Note Preview" className={styles.confirmImg} src={dataUri} />
        {currentStep === 2 && <div className={styles.scanning} />}
      </>
    )
  }

  return (
    <div className={styles.container}>
			<div className={styles.navBar}>
				<NavigationBar
					title='Scan Delivery Note'
          transparent
				/>
			</div>

      <div className={cx('content', 'step'+currentStep ,{contentConfirm: !!dataUri})}>
        {content}
        <div className={styles.scanPending}>
          <div className={styles.scanPendingAnimation} />
          <p className={styles.scanPendingText}>Scanning the file....</p>
          <button
            className={styles.scanPendingButton}
            onClick={()=>{
              setDataUri('')
              setCurrentStep(1);
            }}
          >CANCEL</button>
        </div>

        <div className={styles.confirmContainer}>
					<div
						className={styles.clickArea}
						onClick={()=> {
							setDataUri('')
							setCurrentStep(1);
						}}
						></div>
          <div className={styles.confirm}>
            <ConfirmDetails />
          </div>
        </div>
      </div>
    </div>
  );

}

export default DeliveryNote;
